import {initGrid} from "../products";

$(window).on('load', function() {
    setTimeout(function () {
        if ($('.laktofree .product_tab-pane.active .product-detail').length > 0) {
            $('.laktofree .product_tab-pane.active .product_element--milk-3, .laktofree .product_tab-pane.active .product_element--milk-6').addClass('opacity');
        }
    }, 1200);
})

$(function() {
    $('.laktofree .product_tabs .tab-item').click(function (e){
        e.preventDefault();
        const id = $(this).attr('href');

        if(!$(this).hasClass('active')) {
            const $activeProduct = $('.laktofree .product_tab-content .product_tab-pane.active .product-detail');

            if ($activeProduct) {
                $activeProduct.find('.product-detail_close').trigger('click');
                setTimeout(() => {
                    changeProductType($(this), id);
                }, 600)
            } else {
                changeProductType($(this), id);
            }
        }
    });

    $(document).on('click', '.laktofree .product-grid-item', function (e) {
        $('.laktofree .product_tab-pane.active .product_element--milk-3, .laktofree .product_tab-pane.active .product_element--milk-6').addClass('opacity');
    });

    $(document).on('click', '.laktofree .product-detail_close', function (e) {
        setTimeout(function () {
            $('.laktofree .product_tab-pane.active .product_element--milk-3, .laktofree .product_tab-pane.active .product_element--milk-6').removeClass('opacity');
        }, 600);
    });
});

function changeProductType($navItem, id) {
    $('.laktofree .product_tabs .tab-item').removeClass('active');
    $navItem.addClass('active');

    $('.laktofree .product_tab-content .product_tab-pane').removeClass('active').hide();
    $(id).addClass('active').fadeIn(300, function() {
        initGrid();
    });
}